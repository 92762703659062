import Course from 'js/course/Course'
import server from 'js/app/server'
import config from 'configs/'
import uniqid from 'uniqid'
import Plugin from 'js/plugin/Plugin'
import app from 'js/app/'
import ls from 'local-storage'
import check from 'check-types'

export default class User {
    static deleted = {
        id : 0,
        initials : "?"
    }
    constructor(data) { // { id, [firstname, lastname, username]+ [,this.birthday, this.customInitials]}
        this.id = data.id
        this.firstname = (data.firstname) ? data.firstname : null
        this.lastname = (data.lastname) ? data.lastname : null
        this.nickname = data.nickname ? data.nickname : null
        this.email = data.email ? data.email : null
        this.customInitials = (data.initials) ? data.initials : null
        this.birthday = data.birthday ? new Date(data.birthday) : null
        this.gender = data.gender ? data.gender : 9
        this.avatar = data.avatar
        this.settings = data.settings
    }
    isValid(){
        return check.string(this.firstname) && this.firstname.length &&
        check.string(this.lastname) && this.lastname.length &&
        check.integer(this.gender)
    }
    addCustomData(custom){
        return this
    }
    get alias(){
        return this.nickname || ((this.firstname ? this.firstname : "") + (this.lastname ? (" " + this.lastname) : ""))
    }
    get aliasInitials(){
        return this.nickname ? ("["+this.initials+"] "+this.nickname) : this.alias
    }
    get atomal(){
        return {id : this.id, type : this.type}
    }
    static typePattern = "(pupil|teacher|guardian)"
    static namePattern = "\\w+"
    static serialPattern = new RegExp("^"+this.typePattern+"-("+this.namePattern+")")

    static deserializeId(id){
        let m = id.match(this.serialPattern)
        if(!this.types[m[1]]) throw new Error("Deserialisierung mit unbekanntem User-Typ")
        return this.types[m[1]].get(parseInt(m[2]))
    }
    static deserializeIdToAtomal(id){
        let m = id.match(this.serialPattern)
        //        console.log("deserializeIdToAtomal",id,m)
        return {type : m[1], id : parseInt(m[2])}
    }
    
    serializeId(){
        return this.constructor.type+"-"+this.id
    }
    static async get(id){
        let u = this.find(id)
        let obj = {key : uniqid(), type : "user-request", data : {}}
        //    console.log("GET User",this.type,id);
        obj.data[this.type] = [id]
        if(u) return u
        else {
            let req = await server.request("user",obj)
            return check.emptyObject(req[0]) ? {} : this.addInstance(req[0])
        }
    }
    static async getAllMissing(ids){

        var self = this
        return new Promise((resolve,revoke) => {

            var usersleft = ids.filter(e => !this.find(e))

            if(usersleft.length){
                var data = {
                }
                data[this.type] = usersleft
                server.request("user",{"type" : "user-request", key : uniqid(), data : data})
                    .then((resp) => {
                        console.log("User geladen",this.type, resp)
                        var usrArr = check.array(resp) ? resp : resp[this.type]
                        usrArr = usrArr.filter(e => !check.emptyObject(e))
                        this.addInstance(usrArr)
                        resolve(ids.map(e => this.find(e)).filter(e => e))
                    })
                console.log("Nicht-lokal-vorhandene User ",this.type,usersleft)
            } else resolve(ids.map(e => this.find(e)))


        })

    }
    static get type(){
        return "user"
    }
    static get loggedIn(){
        return this.own ? true : false
    }
    static get asTeacher(){
        return this.own.constructor.type == "teacher"
    }
    static get asPupil(){
        return this.own.constructor.type == "pupil"
    }
    static get asGuardian(){
        return this.own.constructor.type == "guardian"
    }
    static amI(obj){ // for different types like atomal, serializeId (TODO), instance etc
        //        return this.own ? (this.own.id == obj.id && this.own.constructor.type == obj.type) : false
        return obj instanceof User && this.own.serializeId() == obj.serializeId() || check.object(this.own) && this.own.id == obj.id && this.own.constructor.type == obj.type
    }
    static find(id) {
        return (id in this.instances) ? this.instances[id] : null
    }
    static findByAtomal(usrobj){
        return this.types[usrobj.type].find(usrobj.id)
    }
    static addInstance(data) {
        if (this == User) { // object
            for (var i in data)
                this.types[i].addInstance(data[i])
            return null
        }
        if (data instanceof Array) {
            data.forEach(e => this.addInstance(e))
            return null
        }
        if(data.id){
            var usr = new this(data)
            this.instances[usr.id] = usr
        }
        //    console.log(this.name, usr);
        return usr
    }

    static reset(){
        this.own = null
        Plugin.truncate() // Temporäre Plugindaten verwerfen.
        Course.truncate()
    }

    static authenticate(ldata) { // logindata, [appId]
        console.log("Authenticate ",ldata)
        return new Promise((resolve, revoke) => {
            let args = null
            if(check.string(ldata.serial))
                args = {serial : ldata.serial}
            else
                args = {usrname : ldata.type+'-'+ldata.name, usrpwd : ldata.pw}

            $.post(config.server.apiurl+"/login",args)
                .done(data => {
                    console.log(data)

                    if (data.msg) { // Fehlermeldungen
                        revoke(data)
                    } else {
                        ls('username',ldata.name)
                        ls('session',data.sid ? data.sid : true)
                        resolve(data) // sid und version
                    }
                })
                .fail(() => {
                    revoke({msg : "serverNotReachable", type : "danger"})
                })
        })

    }
    static signup(rdata){
        console.log("Signup with ",rdata)
        return new Promise((resolve, revoke) => {
            $.post(config.server.apiurl+"/signup",{rdata : JSON.stringify(rdata)})
                .done(data => {
                    console.log(data)
                    if (data.msg) { // Fehlermeldungen
                        revoke(data)
                    } else {
                        ls('session',data.sid ? data.sid : true)
                        resolve(data) // sid und version
                    }
                })
                .fail(() => {
                    revoke({msg : "serverNotReachable", type : "warning"})
                })
        })
    }

  static own = null;

  static ui = {
      loginCard : null,
      dialogs: {}
  }; // Overriden by Component LoginCard

  static types = {
      pupil: require('./Pupil').default,
      teacher: require('./Teacher').default,
      guardian: require('./Guardian').default
  }
  static parentType = User;
  static instances = {
      pupils: {},
      guardians: {},
      teachers: {}
  }

  get initials() {
      return (this.customInitials) ? this.customInitials : (this.firstname[0] + this.lastname[0])
  }
  get fullname() {
      return this.firstname + " " + this.lastname
  }
  static connect(sid){ // sid nur wenn keine cookies erlaubt sind
      var self = this
      return new Promise(async (resolve, revoke) => { // resolve(agent), revoke(connection)
          await server.connect(sid)
              .catch(e => {
                  revoke(e)
              })

          let agent = await server.waitFor('init')

          if(!agent) return revoke(true)
          console.log(agent)

          server.socket
              .on("logout", (data) => {
                  console.log("Logoutnachricht erhalten", data)
                  app.loader.logout(data)
                  User.own = null
                  ls.remove('session')
                  server.socket
                      .removeAllListeners("logout")
                      .removeAllListeners("course-session")
              })
              .on("course", (obj) => { // data {id, type, data } Eine Session wurde gestartet
                  console.error("Course wegen cyclic-dependency vorher nicht vorhanden gewesen. Dies muss obsolet sein.") // TODO
                  var promise = Course.get(obj.id)
                  if(obj.type == "update")
                      promise.then(c => {
                          c.update(obj.data)
                      })

              })
              .on("userdata", (obj) => { // obj {usr {id, type}, data : {key : val, ...} }
                  let u = self.findByAtomal(obj.usr)
                  for(var i in obj.data)
                      u[i] = obj.data[i]
              })
          User.own = User.types[agent.type]
              .addInstance(agent.usr)
              .addCustomData(agent.custom)

          globalUser = User
          globalcheck = check
          globalServer = server


          //          User.setLogin(true)
          resolve(agent)
      })
  }
  static logout() { // Logge aktiven User aus
      //server.disconnect()
      server.send("user",{type : "user-logout"})
  }


}

import User from 'js/user/User'
import Plugin from 'js/plugin/Plugin'
import Course from 'js/course/Course'
import server from 'js/app/server'
import uniqid from 'uniqid'

export default class Session extends Plugin {
    constructor(obj){
        super(obj) // obj {id: courseid, pluginId}
        this.pluginName = obj.pluginName
        this.participants = obj.participants
        this.teachers = obj.teachers
        this.leader = obj.leader
        this.seatplan = obj.seatplan
        this.pluginData = obj.pluginData ? obj.pluginData : null
        this.typeListener = {}
        this.logs = []
        this.component = null
        this.shouldRefresh = true
        this.lockedseats = obj.lockedseats
        console.log(this)
    }
    send(type,data){
        return server.request(this.socketName,{type: type, key : uniqid(), data : data})
    }
    leave(){
        if(User.loggedIn) this.send("leave",null)
    }
    remove(){
        delete this.constructor.instances[this.id]
    }
    handleData(obj){ // addTypeListener for new Lister with prop type
        var self = this
        //    console.log("handleData",obj.type,obj,self.typeListener)
        if(obj.type in self.typeListener){
            console.log("Typehandler found")
            self.typeListener[obj.type](obj.data)
        }
        if(obj.type == "leave"){
            if(self.constructor.amI(obj.data.usr)){
                self.pluginData = null
                self.removeListener()
                console.log("Session left")
            }

        }

    }
    static truncate(){
        this.instances = {}
    }
    refresh(onlyModule){ // Refreshes React
        if(this.component) this.component.refresh(onlyModule)
        else this.shouldRefresh = true
    // Async
    }
    addListener(){
        var self = this
        server.bind(this.socketName,(data) => self.handleData(data))
        this.addTypeListener("log",(data) => self.logs.push(data))
        this.addTypeListener("enter",(data) => {
            if(User.types[data.usr.type] == User.types.pupil)
                self.participants[data.usr.id] = data.usr
            else if(User.types[data.usr.type] == User.types.teacher)
                self.teachers[data.usr.id] = data.usr
            self.addLogItem(data.usr,"betritt die Session")
        })
        this.addTypeListener("leave",(data) => {
            if(User.types[data.usr.type] == User.types.pupil)
                delete self.participants[data.usr.id]
            else if(User.types[data.usr.type] == User.types.teacher)
                delete self.teachers[data.usr.id]
            self.addLogItem(data.usr,"verlässt die Session")
        })
        this.addTypeListener("mirrorseatplan",(data) => { // data {usr {id,type}, seat {x,y} }
            console.debug("MIRRORPLAN",data)
            for(var i in data.plan) self.seatplan[i] = data.plan[i]
            /*
            if(data.plan.seats) self.seatplan.seats = data.plan.seats
            if(data.plan.objects) self.seatplan.objects = data.plan.objects
*/
            self.addLogItem(data.usr,"passt den Sitzplan an")
        })
        this.addTypeListener("lockseats",(data) => { // data {usr {id,type}, seat {x,y} }
            this.lockedseats = data.value
            self.addLogItem(data.usr,((data.value ? "sperrt" : "entsperrt")+" den Sitzplan"))

        })
        this.addTypeListener("takeseat",(data) => { // data {usr {id,type}, seat {x,y} }
            console.debug("TAKESEAT",data)
            var seats = self.seatplan.seats.map( // Alten Sitzplatz entfernen
                row => row.map(
                    cell => cell == data.usr.id ? 0 : cell
                )
            )
            seats[data.seat.y][data.seat.x] = data.usr.id
            self.seatplan.seats = seats

            self.addLogItem(data.usr,"ist nun auf Platz ("+data.seat.x+"|"+data.seat.y+")")
        })
        this.addTypeListener("leaveseat",(data) => { // data {usr {id,type}}
            var seats = self.seatplan.seats.map( // Alten Sitzplatz entfernen
                row => row.map(
                    cell => cell == data.usr.id ? 0 : cell
                )
            )

            self.seatplan.seats = seats

            self.addLogItem(data.usr,"hat den Platz verlassen")
        })
        this.addTypeListener("setseat",(data) => { // data {eusr {id,type}, usr {id,type}, seat {x,y} }
            console.debug("SETSEAT",data)
            var seats = self.seatplan.seats.map( // Alten Sitzplatz entfernen
                row => row.map(
                    cell => cell == data.usr.id ? 0 : cell
                )
            )
            seats[data.seat.y][data.seat.x] = data.usr.id
            self.seatplan.seats = seats

            self.addLogItem(data.usr,"wurde auf Platz ("+data.seat.x+"|"+data.seat.y+") gesetzt")
        })

        this.addTypeListener("close", (data) => {
            var summary = {pluginId : self.pluginId, data : data}
            console.log("Abschlussdaten",summary)
            self.component.setState({summary : summary}) // TODO Kann ggf. by asynchronem State-Update zu Fehlern führen
            self.removeListener()
        })

    }
    removeListener(){
        server.unbind(this.socketName)
        this.removeAllTypeListeners()
    }

    addTypeListener(type,func){ // obj.data into func
        this.typeListener[type] = func
    }
    removeTypeListener(type){
        delete this.typeListener[type]
    }
    removeAllTypeListeners(){
        this.typeListener = {}
    }
    addLogItem(a,i){ // Agent, Item
        var self = this
        this.logs = this.logs.slice() // Copy for new reference
        this.logs.push({usr : a, item : i})
        this.refresh(false)
    }

    get socketName(){
        return "session-"+this.id
    }
    get course(){
        return Course.find(this.id)
    }
    get entered(){
        return this.pluginData ? true : false
    }
    static amI(usr){
        return usr.id == User.own.id && User.own.constructor.type == usr.type
    }
  static instances = {};
  static templates = {}; // Eintrag entsteht durch Aufruf der Session-Seite

  static start(courseid, templateid){
      console.log("Starte Session: ",this.templates[templateid].pluginId)
  }

}

export default {
    server : {
        //url : location.protocol+"//"+(location.hostname == "localhost" ? "127.0.0.1" : location.hostname) +":3001",//"http://127.0.0.1:3001", // URL zum Socket. Zur Verwendung von SSL https statt http nutzen
        //url : location.protocol+"//"+location.hostname+":3001",//"http://127.0.0.1:3001", // URL zum Socket. Zur Verwendung von SSL https statt http nutzen
        url : location.protocol+"//"+(location.hostname == "localhost" ? location.hostname+":3001" : location.hostname),
        apiurl : location.protocol+"//"+(location.hostname == "localhost" ? location.hostname+":3001" : location.hostname+"/api"),
        session : {
            cookies : {
                enable : true,
                sid : 'pupilize.sid'
            }
        },
        options : {
            path : '/api/socket.io',
            secure : !(location.hostname == "localhost"),
            transports: ['websocket'],
            reconnection: false
        }
    },
    //socketurl : "http://127.0.0.1:3001",//"http://192.168.2.108:3001", // URL zum Socket. Zur Verwendung von SSL https statt http nutzen
    rootPath : "/", // Pfad vom erreichbaren http-Root-Verzeichnis zu Projektordner. Leer lassen falls nicht in einem Unterordner.
    website : {
        domain : "pupilize.com",
        name : "Pupilize",
        admin : {
            name : "Dominik Höltgen",
            email: "admin@pupilize.com"
        }
    },
    ui : {
        loadingDelay : 200
    }
}

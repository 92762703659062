export default class Plugin {
    constructor(obj){ // obj {name, id}
        this.id = obj.id
        this.pluginId = obj.pluginId
        // Plugin-ID
        this.name = obj.name
    }
  static buffer = [];

  static truncate(){
      this.instances = {}
  }

  static addPlugin(obj){ // TODO
      this.templates[obj.id] = obj
      $(".sessionform select[name=type]").append($("<option />",{value : obj.id, text : obj.name}))
      console.log("Moduleloader: "+this.name+"-Plugin '"+obj.id+"' ("+obj.name+") included")
  }
  static getImgPath(pluginid){
      return "plugin/"+this.name.toLowerCase()+"/"+pluginid+"/img/"
  }

  static loadBuffer(){
      for(var i=0;i<this.buffer.length;i++) this.load(this.buffer[i])
  }
  static load(id){
      if(!app.isReady){
          console.log("Moduleloader: App initialization in progress. Buffering request for Plugin '"+id+"'")
          this.buffer.push(id)
          return
      }
      requirejs.config({
          baseUrl : "plugin/"
      })

      console.log("Moduleloader: Asynchronously loading plugin '"+id+"'")
      requirejs([id+"/js/main"], function(){
          console.log("Moduleloader: Plugin '"+id+"' loaded")
      })


  }
  static getInfo(){
      console.log(this)
      //        console.log(this.name)
      //            console.log(this.constructor.name)
      //                console.log(this.prototype)
  }
}

import server from 'js/app/server'
import User from 'js/user/User'
import check from 'check-types'
//import Pupil from './user/Pupil'
//import Teacher from './user/Teacher'
import Session from 'js/plugin/Session'
import {NotificationEmitter} from 'functional'
// import React from 'react'
//import NotificationBody from 'course_elements/awards/NotificationBody'
//() => <NotificationBody user={User.own} personal={true} award={e} />
var datafuncs = {
    refresh : {
        session : e => e ? new Session(e) : null
    },
    update : {},
    add : {
        awardsGiven : (e,inst) => {
            inst.awardsGiven[e.id] = e
            NotificationEmitter.courseEmit(inst,"award",e)
            return inst.awardsGiven
        }
    },
    delete : {}
}



export default class Course {
    constructor(data){
        console.log("Erzeuge Kurs-Instanz aus ",data)
        this.id = data.id
        this.name = data.name
        this.pupils = data.pupils
        this.teachers = data.teachers
        this.needkey = data.needkey
        this.registerkey = data.registerkey
        this.seatplan = data.seatplan
        this.groupplan = data.groupplan
        this.session = data.session ? new Session(data.session) : null
        this.lastupdate = Date.now()
        this.archives = data.archives ? data.archives : null // Archiv-IDs
        this.awards = check.object(data.awards) ? data.awards : null // Auszeichnungen
        this.awardsGiven = check.object(data.awardsGiven) ? data.awardsGiven : {}

    }
    get socketName(){
        return "course-"+this.id
    }
      static component = { // temporary save if current content needs to be updated
          id : null,
          element : null
      }; // React-Component

      static truncate(){ // Bei Kick vom Server
          this.instances = {}
          Session.truncate()
      }

      static instances = {}

      static find(id){
          return (id in this.instances) ? this.instances[id] : null
      }
      static unbind(id){
          delete this.instances[id]
      }
      static get(id) { // id, ReactComponent, callback
          var self = this
          return new Promise(function(resolve,revoke){
              console.log("Course get #",id)
              var c = self.find(id)
              if(!c) {
                  server.request("course",{type : "course-request", key : parseInt(id)})
                      .then(function(data){
                          console.log(data)
                          if(!data || data.data == null) // TODO Serverseitiges Message-object
                              return revoke({"key" : "couldNotGetCourse"})
                          var nc = new self(data.data)
                          self.addInstance(nc)
                              .then(function(inst){
                                  resolve(inst)
                              })

                      })
              } else resolve(c)
          })

      }
      notify(data){ // TODO: Vom Lehrer direkt ausgelöste Notifications
          // toast()
      }

      static deleteInstance(id) { // TODO Wird vom Server ausgelöst. ReactRoute zurück falls offen
          delete this.instances[id]
      }

      getMissingUser(){
          var self = this
          return new Promise((resolve,revoke) => {

              var pupilsleft = this.pupils.filter(id => User.types.pupil.find(id) == null)
              var teachersleft = this.teachers.filter(id => User.types.teacher.find(id)  == null)

              if(pupilsleft.concat(teachersleft).length){
                  server.request("course",{"type" : "user-request", key : this.id, users : {pupil : pupilsleft, teacher : teachersleft}})
                      .then((resp) => {
                          console.log("Neue Kursteilnehmerdaten",resp)
                          User.addInstance(resp)
                          resolve()
                      })
                  console.log("Der Kurs enthält nicht-lokal-vorhandene User ",pupilsleft,teachersleft)
              }


          })
      }

      static addInstance(obj) { // Komplette Kursdaten
          console.log("Kursmanager addData: ", obj)
          var self = this
          return new Promise((resolve, revoke) => {
              if (obj instanceof Array) { // Mehere Daten
                  for (var i = 0; i < obj.length; i++) self.addInstance(obj[i])
                  return
              }

              var add = obj => {
                  let inst = (obj instanceof self) ? obj : new self(obj)
                  self.instances[inst.id] = inst
                  return inst
              }
              // Suche nach fehlenden Daten

              if (obj.pupils)
                  var pupilsleft = obj.pupils.filter(id => User.types.pupil.find(id) == null)

              if (obj.teachers)
                  var teachersleft = obj.teachers.filter(id => User.types.teacher.find(id)  == null)

              if(pupilsleft.concat(teachersleft).length){
                  server.request("course",{"type" : "user-request", key : obj.id, users : {pupil : pupilsleft, teacher : teachersleft}})
                      .then((resp) => {
                          console.log("Kursteilnehmerdaten",resp)
                          User.addInstance(resp)
                          resolve(add(obj))
                          console.log(self.instances)
                      })
                  console.log("Der Kurs enthält nicht-lokal-vorhandene User ",pupilsleft,teachersleft)
              } else resolve(add(obj))

          })
      }
      startSession(args){
          var self = this
          return new Promise((resolve,revoke) => {
              server.request("course",{
                  type : "plugin-startsession",
                  key : this.id,
                  data : args
              })
                  .then((resp) => {
                      resolve(resp)
                  })
                  .catch(revoke)
          })
      }
      getUser(){

      }
      hasPupil(id){
          return this.pupils.includes(id)
      }
      update(obj){ // TODO subtype !!!
          var self = this

          var promises = {
              pupils : () => self.getMissingUser(),
              teachers : () => self.getMissingUser()
          }
          return new Promise((resolve, revoke) => {
              var allpromises = []
              console.log(this,obj)
              if(obj.type == "data" || obj.type == "jsondata"){
                  var subtype = obj.subtype || "refresh"
                  for(var i in obj.data){
                      var prop = (i in datafuncs[subtype]) ? datafuncs[subtype][i](obj.data[i],this) : obj.data[i]
                      if(subtype == "add"){
                          if(!(i in datafuncs[subtype])){
                              if(check.array(this[i])) this[i].push(prop); else if(check.object(this[i])) this[i][prop.id] = prop
                          } else this[i] = prop
                      } else if(subtype == "refresh") this[i] = prop
                      else if(subtype == "delete"){
                          if(!(i in datafuncs[subtype]))
                              if(check.array(this[i])){
                                  this[i].splice(this[i].findIndex(e => e.id == obj.data[i].id),1)
                              } else {
                                  delete this[i][obj.data[i].id]
                              }
                          else this[i] = prop

                      } else if(subtype == "update"){
                          if(!(i in datafuncs[subtype]))
                              if(check.array(this[i])){
                                  this[i][this[i].findIndex(e => e.id == obj.data[i].id)] = obj.data[i]
                              } else {
                                  this[i][obj.data[i].id] = obj.data[i]
                              }
                          else this[i] = prop
                      }
                      if(i in promises) allpromises.push(promises[i]())
                  }
              }
              this.lastupdate = Date.now()
              if(allpromises.length)
                  Promise.all(allpromises)
                      .then(() => resolve(self))
              else resolve(self)




          })


      }

}

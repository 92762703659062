import User from './User'

export default class Pupil extends User {
    constructor(data){
        super(data)
        this.courses = data.courses ? data.courses : []
    }
    addCustomData(custom){
        if(custom){
            this.courseList = custom.courses
        }
        return this
    }
  static notation = "Schüler/in"
  static icon = "child"
  static instances = {}

  static get type(){
      return "pupil"
  }

}

import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import icons from './icons'

export default class AwardIcon extends React.Component {
  static defaultProps = {
      size : "2x"
  }
  static propTypes = {
      icon : PropTypes.string.isRequired,
      effects : PropTypes.object.isRequired,
      size : PropTypes.string,
      extendedProps : PropTypes.object
  }
  render(){
      var optional = {...this.props.extendedProps}
      var icon = icons.find((ic) => this.props.icon == ic.icon)
      if(icon.secondColor){
          optional.style={"--fa-primary-color" : icon.color, "--fa-secondary-color" : icon.secondColor }
      } else optional.color=icon.color

      return <FontAwesomeIcon
          className="m-2"
          size={this.props.size}
          icon={['fad',this.props.icon]}
          {...optional}
          {...this.props.effects}
      />
  }

}

import React from 'react'
import {Spinner} from 'reactstrap'
import PropTypes from 'prop-types'

export default class PromiseLoader extends React.Component {
  static propTypes = {
      promise : PropTypes.instanceOf(Promise),
      render : PropTypes.func.isRequired
  }
  static defaultProps = {
      render : result => result
  }
  constructor(data){
      super(data)
      this.state = {loaded : false, result : null}
  }
  componentDidMount(){
      this._mounted = true
      this.props.promise
          .then((val) => {
              if(this._mounted)
                  this.setState({loaded : true, result : val})
          })
  }
  componentWillUnmount(){
      this._mounted = false
  }
  render(){
      return this.state.loaded ? this.props.render(this.state.result) : <Spinner />

  }
}

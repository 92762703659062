import React, {Suspense, Component } from 'react'
import {Spinner, Container} from 'reactstrap'
import {Loadable} from 'functional'
import {Loading} from 'alerts'
//import User from 'js/user/User'
//import SVGInline from 'react-svg-inline'
import PupilizeIconSVG from 'img/icon/transparent.svg'
import app from 'js/app'
import config from 'configs/'
import './scss/app.scss'


//import AppLoadable from './AppLoader'
const LoadingWrapper = (props) => <Container fluid>
    <div className="w-xl-25 m-auto">
        <Spinner size="sm" color="primary" /> Pupilize {"version "+app.version}<br />
        <br />
        <PupilizeIconSVG />
    </div>
</Container>


const AppLoadable = Loadable({
    loader : () => import('./AppLoader'),
    loading : LoadingWrapper,
    delay : config.ui.loadingDelay
})

export default class App extends Component {
    constructor(props){
        super(props)
    }

    render(){
        return(
            <Suspense fallback={<Loading />}>
                <AppLoadable />
            </Suspense>
        )
    }
}

import React from 'react'
import {toast} from 'react-toastify'
import User from 'js/user/User'
import Pupil from 'js/user/Pupil'

import NotificationBody from 'course_elements/awards/NotificationBody'

const notifications = {
    award : (course,data) => {
        var a = course.awards[data.award_id]
        var p = Pupil.find(data.pupil_id)
        var amount = Object.values(course.awardsGiven).filter(e => e.pupil_id == p.id && e.award_id == a.id).length



        return <NotificationBody amount={amount} user={p} personal={User.amI(p)} award={a} />
    }
}

export default class NotificationEmitter {// extends React.Component {
    static emit(type,data){
        console.log("NotificationEmitter", type, data)
        toast(() => notifications[type](data))
    }
    static courseEmit(course,type,data){
        console.log("NotificationEmitter", type, data)
        toast(() => notifications[type](course,data))
    }
}

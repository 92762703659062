import User from './User'
import server from 'js/app/server'

export default class Guardian extends User {
    constructor(data){
        super(data)
        this.pupils = []

    }
  static notation = "Erziehungsberechtige/r"
  static icon = "user-shield"
  static get type(){
      return "guardian"
  }

  static instances = {}



}

/* Grundlegende Informationen und Funktionen zur Anwendung */
/* TODO Übernommen aus der alten Version */
//import utils from 'js/utils'
import packagejson from 'js/../../package.json'

var app = {
    version: packagejson.version, // Version für abgleich mit dem Server
    isReady : false,
    close : function(){ // Schließe die App
        if(app.isApp()) navigator.app.exitApp()
    },
    loader : null,
    disableReason : [],
    disable : function(reason){
        var reasons = {
            "connection" : "Es besteht keine Verbindung zum Server.<br>Die letzte Serveranfrage wird automatisch wiederholt.",
            "response" : "Es wird auf eine Antwort des Servers gewartet. Bitte warten...",
            "courses" : "Kursdaten werden angefordert. Bitte warten..."
        }
        app.disableReason.push(reason)
        /*
          var $content = $("<ul />");
          for(var i=0;i<app.disableReason.length;i++)
            $content.append($("<li />",{"class" : "disabledreason-"+app.disableReason[i]}).html(reasons[app.disableReason[i]]));

          $("#dialog-disabled .modal-body").html($content);
          $("#dialog-disabled").modal('show');
*/

    },
    enable : function(reason){

    },
    isApp : function(){ // true falls App, false falls Browser
        return (document.URL.indexOf( 'http://' ) === -1 && document.URL.indexOf( 'https://' ) === -1)
    },
    report : function(type,desc,data){ // Wird bei Fehler aufgerufen. Fehlerbericht wird an den Server übermittelt.
        console.log("Erstelle Fehlerbericht")
        var msg = ""
        switch(type){
        case 0: // JavaScript Runtime-Fehler
            msg = "Es ist ein Laufzeitfehler aufgetreten.<br>Bitte vergewissern Sie sich, dass sie die neuste Version haben.<br>Sollte dieses Problem erneut auftreten, kontaktieren Sie den Support."
            break
        case 1: // Speicherfehler
            msg = "Dein lokaler Speicher ist nicht mehr lesbar."
            break
        case 2:
            msg = ""
            break
        }
        app.alert(msg+"<br><br>Der lokale Speicher wird gel&ouml;scht.")
        app.changePage("home")
        //Memory.truncate();

        var c = null
        try{ // Sammle Software und Betriebssystemdaten
            c = {
                appVersion : app.version,
                isApp : app.isApp(),
                appPage : Content.activePage,
                viewerAgent: navigator.userAgent,
                viewerName : navigator.appName,
                viewerProduct: navigator.product,
                viewerVersion : navigator.version,
                viewerLanguage: navigator.language,
                viewerPlatform : navigator.platform,
                viewerPermissions : navigator.permissions
            }

        } catch(e){
            console.log("Fehler beim Sammeln der Clientdaten")
            c = {errorname : e.name, msg : e.message}
        }
        if(server.socket.connected)
            server.send("appreport",{type: type, desc: desc, data : data, client : c, user : user.data.id}) // Sende an den Server

    }


}
export default app

export default [
    {icon : 'star', color : 'orange'},
    {icon : 'hands-helping'},
    {icon : 'head-side-brain', color : 'blue'},
    {icon : 'dumbbell', color : 'black'},
    {icon : 'backpack', color : 'saddlebrown'},
    {icon : 'campfire', color : 'brown', secondColor : "orangered"},
    //    {icon : 'cookie', color : 'black', secondColor : "chocolate"},
    {icon : 'cookie-bite', color : 'black', secondColor : "chocolate"},
    {icon : 'chalkboard', color : 'seagreen', secondColor : "saddlebrown"},
    {icon : 'clock', color : 'black', secondColor : "burlywood"},
    {icon : 'broom', color : 'burlywood'}
]

import React from 'react'
import PropTypes from 'prop-types'
import {Table, Progress, Badge, Alert} from 'reactstrap'
import check from 'check-types'
import server from 'js/app/server'
import User from 'js/user/User'
import textInterpreter from 'js/user/textInterpreter'
import Course from 'js/course/Course'
import icons from 'course_elements/awards/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AwardIcon from './AwardIcon'
import {withTranslation} from 'react-i18next'
import {NotificationEmitter} from 'functional'

export default withTranslation('course')(class MilestoneTable extends React.Component {
  static defaultProps = {
      amount : 0,
      showBar : true
  }
    static propTypes = {
        award : PropTypes.object.isRequired,
        amount : PropTypes.number,
        showAll : PropTypes.bool,
        showBar : PropTypes.bool
    }
    constructor(data){
        super(data)
    }
    render(){
        var {award, amount, t} = this.props
        var {icon, title, color, text, courseText, effects, milestones} = award
        milestones = [...milestones].sort((a,b) => a.mark - b.mark)
        var milestonesShow = [...milestones].sort((a,b) => a.mark - b.mark)
        var milestonesGot = milestonesShow.filter(e => amount >= e.mark).length
        if(!this.props.showAll)
            milestonesShow.length = milestonesGot
        var milestoneReached = milestonesShow.find(e => amount == e.mark)
        return <Table size="sm">
            {
                milestones.length > 0 && <thead>
                    <tr>
                        <th colSpan={2}>{t('settings.awards.fields.milestone_plural')}</th>
                    </tr>
                </thead>
            }
            <tbody>
                {milestonesShow.map((e,i) => <tr key={i}>
                    <td>{e.mark <= amount ? <FontAwesomeIcon color="green" icon="check" /> : e.mark}</td><td>{e.title}</td>
                </tr>)}
                {
                    milestones.length > 0 && milestonesGot >= milestones.length && <tr>
                        <td colSpan={2}>
                            <Badge color="light"><FontAwesomeIcon color="green" icon="check" /> {t('settings.awards.txt.gotAllMilestones')}</Badge>
                        </td>
                    </tr>
                }
                {
                    this.props.showBar && <tr>
                        <td colSpan={2}>
                            <div className="w-100 position-relative">
                                <div className="w-100 position-absolute text-center" style={{marginTop : -6}}>
                                    <Badge>{milestonesGot >= milestones.length ? (amount+" "+t('titles.award',{count : amount})) : (amount+"/"+milestones[milestonesGot].mark)}</Badge>
                                </div>
                                <Progress value={milestonesGot >= milestones.length ? 100 :amount} max={milestonesGot >= milestones.length ? 100 :milestones[milestonesGot].mark} color={milestonesGot >= milestones.length ? "success" : "primary"} animated={milestonesGot < milestones.length} />
                            </div>
                            {
                                milestonesGot < milestones.length && <>{t('settings.awards.txt.next')} <Badge>{milestones[milestonesGot].title}</Badge></>
                            }
                        </td>
                    </tr>
                }
            </tbody>
        </Table>
    }

})

import React from 'react'
import PropTypes from 'prop-types'
import {Table, Progress, Badge, Alert} from 'reactstrap'
import check from 'check-types'
import server from 'js/app/server'
import User from 'js/user/User'
import textInterpreter from 'js/user/textInterpreter'
import Course from 'js/course/Course'
import icons from 'course_elements/awards/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AwardIcon from './AwardIcon'
import {withTranslation} from 'react-i18next'
import {NotificationEmitter} from 'functional'
import MilestoneTable from './MilestoneTable'

export default withTranslation('course')(class NotificationBody extends React.Component {
    static propTypes = {
        user : PropTypes.instanceOf(User).isRequired,
        award : PropTypes.object.isRequired,
        personal : PropTypes.bool,
        amount : PropTypes.number
    }
    static defaultProps = {
        amount : 1
    }
    constructor(data){
        super(data)
        this.state = {
            previous : true,
            hold : true
        }
        this.timerStep = null
        this.timerNext = null
    }
    componentWillUnmount(){
        if(this.timerStep) clearTimeout(this.timerStep)
        if(this.timerNext) clearTimeout(this.timerNext)
    }
    componentDidMount(){
        this.timerStep = setTimeout(() => this.setState({previous : false}),1500)
        this.timerNext = setTimeout(() => this.setState({hold : false}),1000)
    }
    render(){
        var {award, personal, user, amount, t} = this.props
        if(amount == 0) amount++
        var compareamount = this.state.hold && personal ? amount-1 : amount
        if(this.state.previous && personal) amount--

        var {icon, title, color, text, courseText, effects, milestones} = award
        milestones = [...milestones].sort((a,b) => a.mark - b.mark)
        var milestonesShow = [...milestones].sort((a,b) => a.mark - b.mark)
        var milestonesGot = milestonesShow.filter(e => amount >= e.mark).length
        milestonesShow.length = milestonesGot
        var milestoneReached = milestonesShow.find(e => compareamount == e.mark)
        return <><div className="w-100"><AwardIcon
            extendedProps={{pull : "left"}}
            className="m-2"
            icon={icon}
            size="3x"
            effects={effects}
        />
        <strong>{title}</strong><br />
        {textInterpreter(user,"",(personal ? text : courseText) || "")}
        </div>
        {milestoneReached && <><div className="p-1"><Badge>{milestoneReached.title}</Badge><br />
            {textInterpreter(user,"",(personal ? milestoneReached.text : milestoneReached.courseText) || "")}
        </div></>}
        {personal && milestones.length > 0 && <div className="w-100">
            <MilestoneTable award={award} amount={compareamount} />
        </div>}

        </>
    }

})

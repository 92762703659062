import React from 'react'
import ReactDOM from 'react-dom'

import './index.html'
import './multi.html'
import './browserconfig.xml'
import App from './App'
import * as serviceWorker from './serviceWorker'
//import 'jquery';


ReactDOM.render(
    <App />,
    document.getElementById('app')
)




module.hot.accept()
serviceWorker.unregister()

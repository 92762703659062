import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {ListGroup, ListGroupItem} from 'reactstrap'

const iconType = {
    "success" : "check-circle",
    "danger" : "times-circle",
    "info" : "exclamation-circle",
    "warning" : "exclamation-triangle"
}
const colorType = {
    "success" : "green",
    "danger" : "red",
    "info" : "blue",
    "warning" : "yellow"

}

export default class ServerMessageList extends React.Component {
  static propTypes = {
      msg : PropTypes.array,
      t : PropTypes.func
  }
  render(){
      const {t,msg} = this.props
      return <ListGroup>
          {
              msg.map(e => <ListGroupItem color={e.type} key={e.key}>
                  <div className="d-flex">
                      <div className="mr-3">
                          <FontAwesomeIcon pull="left" icon={['fad',iconType[e.type]]}
                              size="2x" />
                      </div>
                      <div className="font-weight-bold m-auto">
                          {t(e.key,e.args)}
                      </div>
                  </div>
              </ListGroupItem>)
          }
      </ListGroup>
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import {Alert, Spinner} from 'reactstrap'
import i18next from 'i18next'
import {withTranslation} from 'react-i18next'
export default withTranslation('basic')(class InfoMessage extends React.Component {
    static defaultProps = {
        type : "default"
    }
    static propTypes = {
        msg : PropTypes.string,
        type : PropTypes.string,
        t : PropTypes.func
    }
    render(){
        const {t,msg,type,spinner,error} = this.props
        const text = msg || t('info.'+type)
        return <Alert className="mt-2" color="info">
            <div className="d-flex flex-row">
                <div className="font-weight-bold m-auto">{text}</div>
            </div></Alert>
    }
})

//var io = require('socket.io-client')
import config from 'configs/'
import app from 'js/app/'
import User from 'js/user/User'
import Course from 'js/course/Course'
import io from 'socket.io-client'
import wildcard from 'socketio-wildcard'
var wildcardPatch = wildcard(io.Manager)

$.ajaxSetup({
    crossDomain: true,
    xhrFields: {
        withCredentials: true
    }
})

var server = {
    state : 0, //     0 : "Nicht verbunden", 1 : "Serveranfrage...", 2: "Versionsabgleich...", 3 : "Serververbindung herstellen...", 4 : "Verbunden"
    isConnected : function(){return this.state == 4},
    socket : null,
    uploader : null,
    uptime : null, // Timestamp vom Start des Servers
    sendBuffer : [],
    uploads : [],
    requestLists : {
    // listener : {id : function,...}
    },
    request : function(listener, data){
        return new Promise(function(resolve,revoke){
            var locallistener = (data && data.type) ? (listener+"-"+data.type) : listener
            locallistener = (data && data.key) ? (locallistener+"#"+data.key) : locallistener
            console.log("Setze listener ",locallistener)
            server.bind(locallistener,
                (data) => {
                    console.log("REQUEST-ANSWER",locallistener,data)
                    server.unbind(locallistener)
                    resolve(data)
                })
            server.send(listener,data)
        })
    },
    bind : function(listener,func){
        console.log("server: bind listener ",listener)
        if(this.socket)
            this.socket.on(listener,func)
    },
    waitFor : (listener) => {
        return new Promise((resolve,revoke) => {
            server.bind(listener, data => {
                resolve(data)
                server.unbind(listener)
            })
        })
    },
    unbind : function(listener){
        console.log("server: unbind listener ",listener)
        if(this.socket)
            this.socket.removeAllListeners(listener)
    },
    listeners : {
        connect : function(){ // Verbinde zum Server
            console.log("Persistente Verbindung zum Server hergestellt")
            server.state = 4
            /*
         $("#disconnected-icon").hide();

         var loginInBuffer = function(){
           for(var i=0;i<server.sendBuffer.length;i++)
             if(server.sendBuffer[i].name == "login") return false;
           return true;
         }

               var j=0;
               for(var i=0;i<server.sendBuffer.length;i++){
                   var cmd = server.sendBuffer[j];
                   if(!cmd.online){
                   server.send(cmd.name,cmd.data);
                   server.sendBuffer.shift();
                   } else j++;
               }
*/
            //server.sendBuffer = []; // Loesche den Puffer
        },
        connect_failed : function(){ // Verbindung fehlgeschlagen. Mit Passport abgelehnt ist nicht gemeint.
            server.state = 2
            console.log('Persistente Verbindung zum Server fehlgeschlagen')
        },
        disconnect : function(){ // Verbindung wurde getrennt
            Course.truncate()
            server.state = 0
            if(User.own) app.loader.logout({msg : "disconnected", type : "danger"})
            console.log("Persistente Verbindung getrennt...")
            /*
         $("#disconnected-icon").show();

         if(user.online)
         user.sessions = user.data.sessions; // Sichere Sessiondaten, um autom. wieder einzutreten

         //user.deleteRelatedData();
         //user.online = false;
         user.setLogout(false);
         */
        }
    },
    resetListeners(){

    },
    connect : (sid) => {
        console.log("Serververbindungsversuch...",config)
        server.state = 1
        return new Promise((resolve,revoke) => { // TODO Nachricht anzeigen, wenn nicht verbunden
            try {
                if(sid) config.server.options.query = "session_id="+sid
                server.socket = io(config.server.url, config.server.options)
                    .on('connect_error', () => {
                        revoke()
                    })
                    server.socket.on('version', (v) => {
                        if(config.version == v || true){
                            resolve()
                            server.listeners.connect()
                        } else revoke(["wrongVersion",{client : config.version, server : v}])
                    })
                    .on('connect', () => {
                        server.state = 3
                        resolve()

                    })
                    .on('connect_error',() => {
                      server.listeners.connect_failed()
                        revoke({type : "error", key : "connectionFailed"})

                    })
                    .on('connect_failed',() => {

                    })
                    .on('disconnect',() => {
                        server.listeners.disconnect()
                    })
                    .on('*',function(data){
                        console.log("DATA RECEIVED '",data.data[0],"'",data.data[1])
                    })
//                    server.socket!.open()


            } catch(e){
                revoke(["technicalError"])
                console.log(e)
            }


        })

    },
    disconnect : function(){
        console.log("Trenne Websocket-Verbindung manuell")
        if(server.socket) server.socket.disconnect()
    },
    send : function(name,data){
        if(this.socket != null && this.isConnected()){
            this.socket.emit(name,data)
            console.log("Sende Daten '"+name+"': ",data)
            return true
        }
        /*
        console.log("Speichere Serveranfrage '"+name+"' - Online: "+user.online);
        this.sendBuffer.push({name : name, data:data, online : user.online});
        app.disable("connection");
        */
        return false
        //app.alert("Keine Verbindung zum Server vorhanden");
    }
}

export default server

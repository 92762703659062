import React from 'react'
import PropTypes from 'prop-types'
import {Alert, Spinner} from 'reactstrap'
import i18next from 'i18next'
import {withTranslation} from 'react-i18next'
export default withTranslation('basic')(class Loading extends React.Component {
    static defaultProps = {
        type : "default",
        spinner : "grow"
    }
    static propTypes = {
        msg : PropTypes.string,
        type : PropTypes.string,
        spinner : PropTypes.string
        //        t : PropTypes.func
    }
    render(){
        const {t,msg,type,spinner} = this.props
        const text = msg || t('loading.'+type)
        return <Alert className={this.props.className} color="info">
            <div className="d-flex flex-row">
                <div className="mr-2"><Spinner color="primary" type={spinner} size="md">{text}</Spinner></div>
                <div className="font-weight-bold m-auto">{text}</div>
            </div>
        </Alert>

    }
})

import React from 'react'

function loadScript(src) {
    return new Promise((resolve,revoke) => {
        var head = document.getElementsByTagName('head')[0]
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = src
        script.onload = resolve
        script.onerror = revoke
        head.appendChild(script)
    })
}
function isScriptLoaded(src){
    return document.querySelector('script[src="' + src + '"]') ? true : false
}

export default class ScriptLoader extends React.Component {
    constructor(data){
        super(data)
        this.state = {loaded : isScriptLoaded(this.props.src)}
    }
    load(){
        var src = this.props.src
        if(!isScriptLoaded(src))
            loadScript(src)
                .then(() => {
                    this.setState({loaded : true})
                })
                .catch(e => {
                    console.error(e)
                })
        else resolve()
    }
    componentDidMount(){
        if(!this.state.loaded) this.load()
    }
    render(){
        return <>
            {
                this.state.loaded && this.props.children
            }
        </>
    }

}

import User from './User'

const defaultPermissions = {}

export default class Teacher extends User {
    constructor(data){
        super(data)
        this.permissions = data.permissions || defaultPermissions
        this.courses = data.courses ? data.courses : []
        this.groups = data.groups
    }
    addCustomData(custom){
        if(custom){
            this.courseList = custom.courses
        }
        return this
    }
  static notation = "Lehrer/in"
  static icon = "chalkboard-teacher"
  static instances = {}

  static get type(){
      return "teacher"
  }

}

import React, {Suspense} from 'react'
import {Spinner} from 'reactstrap'
// react-loadable API with Suspense
/*
Loadable({
    loader : () => import('pages/u/Courses'),
    loading : Loading,
    delay : config.ui.loadingDelay
})

*/
const Loadable = ({loader, loading}) => {
    const Cmpnt = React.lazy(loader) // loader: () => import(...)
    const Loading = loading || (() => <Spinner color="primary" />)
    return (props) => <Suspense fallback={<Loading />}>
        <Cmpnt {...props} />
    </Suspense>
}

export default Loadable

/*
Loadable({
    loader : () => import('pages/u/profiles/Main'),
    loading : Loading,
    delay : config.ui.loadingDelay
})
*/

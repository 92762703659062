import React from 'react'
import PropTypes from 'prop-types'
import {Alert, Spinner} from 'reactstrap'
import {WarningMessage, ErrorMessage, InfoMessage, SuccessMessage, Loading} from './'
import i18next from 'i18next'
import {withTranslation} from 'react-i18next'

const alerts = {
    loading : Loading,
    warning : WarningMessage,
    error : ErrorMessage,
    info : InfoMessage,
    success : SuccessMessage,
    danger : ErrorMessage
}

export default withTranslation('basic')(class Loading extends React.Component {
    static defaultProps = {
        type : "default",
        spinner : "grow",
        alert : "info"
    }
    static propTypes = {
        msg : PropTypes.string,
        type : PropTypes.string,
        spinner : PropTypes.string,
        alert : PropTypes.string,
        //        t : PropTypes.func
    }
    render(){
        const {t,msg,type,alert,spinner} = this.props
        const Cmpnt = alerts[alert]
        return <Cmpnt type={type} msg={msg} spinner={spinner} />
    }
})

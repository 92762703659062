
const variables = {
    inits : (usr) => usr.initals,
    first : (usr) => usr.firstname,
    last : (usr) => usr.lastname,
    alias : (usr) => usr.alias
}

export default function textInterpreter(usr,ns,text){
    for(var i in variables)
        text = text.replace(("$"+(ns)+i), variables[i](usr))

    return text
}
